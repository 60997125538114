import './style.css'
import * as THREE from 'three'
import {
    OrbitControls
} from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import CANNON, {
    World
} from 'cannon'
import {
    GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader.js'
import {
    MeshNormalMaterial, SpotLight
} from 'three'


const gltfLoader = new GLTFLoader()
const model = new THREE.Object3D();
/**
 * Debug
 */
const gui = new dat.GUI()
const debugObject = {}
debugObject.createSphere = () => {
    createSphere(
        Math.random() * 0.5, {
            x: (Math.random() - 0.5) * 3,
            y: 3,
            z: (Math.random() - 0.5) * 3
        })
}
gui.add(debugObject, 'createSphere')

debugObject.createBox = () => {
    createBox(
        Math.random(),
        Math.random(),
        Math.random(), {
            x: (Math.random() - 0.5) * 3,
            y: 3,
            z: (Math.random() - 0.5) * 3
        })
}
gui.add(debugObject, 'createBox')


/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Update all materials
 */
const updateAllMaterials = () => {
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.material.envMap = environmentMap
            child.material.envMapIntensity = debugObject.envMapIntensity
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
        }
    })
}



//sounds
const hitSound = new Audio("./sounds/meow_002.mp3")
const playHitSound = (collision) => {
    const impactStrength = (collision.contact.getImpactVelocityAlongNormal())
    if (impactStrength > 1) {
        hitSound.volume = Math.random()
        hitSound.currentTime = 0
        hitSound.play()
    }
}
/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const cubeTextureLoader = new THREE.CubeTextureLoader()

const environmentMapTexture = cubeTextureLoader.load([
    '/textures/environmentMaps/catBg/px.png',
    '/textures/environmentMaps/catBg/nx.png',
    '/textures/environmentMaps/catBg/py.png',
    '/textures/environmentMaps/catBg/ny.png',
    '/textures/environmentMaps/catBg/pz.png',
    '/textures/environmentMaps/catBg/nz.png'
])
environmentMapTexture.encoding = THREE.sRGBEncoding

scene.background = environmentMapTexture
scene.environment = environmentMapTexture
// physics
// world
const world = new CANNON.World()
world.broadphase = new CANNON.SAPBroadphase(world)
world.allowSleep = true
world.gravity.set(0, -9.82, 0)


// Materials
const defaultMaterial = new CANNON.Material('default')
const defaultContactMaterial = new CANNON.ContactMaterial(
    defaultMaterial,
    defaultMaterial, {
        friction: 0.1,
        restitution: 0.9
    }
)
world.addContactMaterial(defaultContactMaterial)
world.defaultContactMaterial = defaultContactMaterial

// const sphereShape = new CANNON.Sphere(0.5)
// const sphereBody = new CANNON.Body({
//     mass:1,
//     position: new CANNON.Vec3(0,3,0),
//     shape: sphereShape
// })
// sphereBody.applyLocalForce(new CANNON.Vec3(150,0,0), new CANNON.Vec3(0,0,0))

// world.addBody(sphereBody)

// floor
const floorShape = new CANNON.Plane()
const floorBody = new CANNON.Body()
floorBody.mass = 0
//↑ cannonでは動かないことを意味する
floorBody.addShape(floorShape)
floorBody.quaternion.setFromAxisAngle(
    new CANNON.Vec3(-1, 0, 0),
    Math.PI * 0.5
)


world.addBody(floorBody)


/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(1000, 1000),
    new THREE.MeshStandardMaterial({
        color: '#111111',
        metalness: 0.6,
        roughness: 0.8,
        envMap: environmentMapTexture
    })
)
floor.receiveShadow = true
floor.rotation.x = -Math.PI * 0.5
scene.add(floor)

/**
 * Lights
 */
// const ambientLight = new THREE.HemisphereLight(0xffffff, 1)
// scene.add(ambientLight)


const directionalLight = new THREE.DirectionalLight(0x31FCFC, 1.6)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)

directionalLight.position.set(10, 0, 0)
scene.add(directionalLight)
// const dirLightHelper = new THREE.DirectionalLightHelper( directionalLight );

// scene.add( dirLightHelper );


const spotlight = new THREE.SpotLight(0xFCAA31,4,20,Math.PI *0.1,0.25,1)
spotlight.position.set(0,10,2)
spotlight.castShadow = true
spotlight.shadow.mapSize.set(1024, 1024)
scene.add(spotlight)

// const spotLightHelper = new THREE.SpotLightHelper( spotlight );
// scene.add( spotLightHelper );

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    renderer.setClearColor(0x00ffff, 1)
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.2, 100)
camera.position.set(0, 0, 7)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.maxPolarAngle = Math.PI / 2 * 0.8;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setClearColor(0x00ffff, 1)
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


// Utils
const objectsToUpdate = []

const sphereGeometry = new THREE.SphereBufferGeometry(1, 20, 20)
const sphereMaterial = new THREE.MeshStandardMaterial({
    metalness: 0.3,
    roughness: 0.4,
    envMap: environmentMapTexture
})

//create sphere
const createSphere = (radius, position) => {
    // THREE.js mesh
    const mesh = new THREE.Mesh(
        sphereGeometry,
        sphereMaterial
    )
    mesh.scale.set(radius, radius, radius)
    mesh.castShadow = true
    mesh.position.copy(position)
    scene.add(mesh)

    //cannon.js body
    const shape = new CANNON.Sphere(radius)
    const body = new CANNON.Body({
        mass: 1,
        position: new CANNON.Vec3(0, 3, 0),
        shape,
        material: defaultMaterial
    })
    body.position.copy(position)
    // body.addEventListener('collide', playHitSound)
    world.addBody(body)

    //save in  objects to update
    objectsToUpdate.push({
        mesh,
        body
    })
}

createSphere(0.5, {
    x: 0,
    y: 3,
    z: 0
})

//createBox
const boxGeometry = new THREE.BoxBufferGeometry(1, 1, 1)
const boxMaterial = new THREE.MeshStandardMaterial({
    metalness: 0.3,
    roughness: 0.4,
    envMap: environmentMapTexture
})

//create sphere
const createBox = (width, height, depth, position) => {
    // THREE.js mesh
    const mesh = new THREE.Mesh(
        boxGeometry,
        boxMaterial
    )
    mesh.scale.set(width, height, depth)
    mesh.castShadow = true
    mesh.position.copy(position)
    scene.add(mesh)

    //cannon.js body
    const shape = new CANNON.Box(new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5))
    const body = new CANNON.Body({
        mass: 1,
        position: new CANNON.Vec3(0, 3, 0),
        shape,
        material: defaultMaterial
    })
    body.position.copy(position)
    // body.addEventListener('collide', playHitSound)
    world.addBody(body)

    //save in  objects to update
    objectsToUpdate.push({
        mesh,
        body
    })
}
/**
 * Models /cat visual body and
 */
let catMesh = null
let mixer = null

gltfLoader.load(
    '/low-poly_oldxian_comix_cat/scene.gltf',
    (gltf) => {
        const model = gltf.scene
        model.name = "cat"
        var catMesh = []
        model.scale.set(0.005, 0.005, 0.005)
        model.position.set(0, 0.7, 0)
        model.rotation.y = Math.PI * 0.5
        model.traverse((child) => {
            if (child.isMesh) {
                catMesh.push(child)
                child.castShadow = true
                child.receiveShadow = true
            }
        })
        scene.add(model)


      
        // updateAllMaterials()
        mixer = new THREE.AnimationMixer(model)
        const action = mixer.clipAction(gltf.animations[0])
        action.play()

    }
)

console.log(model)

// cannonを入れる
//cannon.js body
const catShape = new CANNON.Box(new CANNON.Vec3(1, 1, 1))
const catBody = new CANNON.Body({
    mass: 1,
    position: new CANNON.Vec3(0, 3, 0),
    shape: catShape,
    material: defaultMaterial
})
console.log(catMesh)
catBody.position.set(0, 0.1, 0)
catBody.addEventListener('collide', playHitSound)
world.addBody(catBody)

/**
 * Animate
 */
const clock = new THREE.Clock()
let oldElapsedTime = 0
const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - oldElapsedTime
    oldElapsedTime = elapsedTime
    if (mixer) {
        mixer.update(deltaTime)
    }
    //    catBody.applyForce(new CANNON.Vec3(-0.5,0,0), catBody.position)
    // // update physics world
    world.step(1 / 60, deltaTime, 3)

    for (const object of objectsToUpdate) {
        object.mesh.position.copy(object.body.position)
        object.mesh.quaternion.copy(object.body.quaternion)
    }
    model.position.copy(catBody.position)

    //  // vec3でもvector３でもOK
    // sphere.position.copy(sphereBody.position)

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()